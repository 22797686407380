import { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Ico } from "@/assets/icons";
import { TestIds } from "@/utils/testIds";
import NotificationItem from "./NotificationItem";
import { NotificationData, NotificationsMenuProps } from "./utils";
import { t } from "i18next";
import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import {
  NewNotificationDocument,
  NewNotificationSubscription,
} from "./graphql/newNotification.generated";
import {
  GetNotificationByUserIdDocument,
  GetNotificationByUserIdQuery,
} from "./graphql/getNotifications.generated";
import { UpdateNotificationStatusDocument } from "./graphql/updateNotificationStatus.generated";
import { useUser } from "@/providers/useUser";
import { BigSpinner } from "@/components/BigSpinner";
import { noCacheHeaders } from "@/utils/headers";
import PostDetail from "@/components/Forum/Post/PostDetail";
import {
  GetPostByIdDocument,
  GetPostByIdQuery,
} from "@/components/Forum/Post/graphql/GetPostById.generated";
import { Post } from "@/schemaTypes";
import { useNavigate } from "react-router-dom";
import { PublicRoute } from "@/AppRoute";
import { colors } from "@/components/Theme/colors";

const NotificationsMenu = ({ onToggleBlur }: NotificationsMenuProps) => {
  const { user } = useUser();
  const userId = user?.id;
  const [postId, setPostId] = useState<string | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const {
    isOpen: openDetail,
    onOpen: openPostDetail,
    onClose: closePostDetail,
  } = useDisclosure();

  const [
    fetchUserNotifications,
    {
      data: userNotificationsData,
      loading: userNotificationsLoading,
      refetch: refetchNotificationsData,
    },
  ] = useLazyQuery<GetNotificationByUserIdQuery>(
    GetNotificationByUserIdDocument,
    {
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
      variables: { userId },
    }
  );
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  // const { data: newNotificationData } =
  //   useSubscription<NewNotificationSubscription>(NewNotificationDocument);
  const [loadPost, { data: postDetailData }] = useLazyQuery<GetPostByIdQuery>(
    GetPostByIdDocument,
    {
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    }
  );
  useEffect(() => {
    if (userId) {
      fetchUserNotifications();
    }
  }, [fetchUserNotifications]);
  const formatNotification = (
    record: NotificationData | null
  ): NotificationData => ({
    _id: record?._id || "", // eslint-disable-line no-underscore-dangle
    description: record?.description || {
      eventId: "",
      eventType: "",
      eventName: "",
      descriptionEvent: "",
      userEventId: "",
      userName: "",
    },
    status: record?.status || false,
    link: record?.link || "",
    registerDate: record?.registerDate
      ? new Date(record.registerDate)
      : new Date(),
    author: record?.author || { id: "", name: "" },
  });

  useEffect(() => {
    if (userNotificationsData?.getNotificationByUserId?.notificationRecords) {
      const fetchedNotifications =
        userNotificationsData.getNotificationByUserId.notificationRecords
          .filter((record): record is NotificationData => record !== null)
          .map(formatNotification);
      setNotifications(fetchedNotifications);
    }
  }, [userNotificationsData]);

  // useEffect(() => {
  //   if (newNotificationData?.newNotification?.notificationRecords) {
  //     const newNotifications =
  //       newNotificationData.newNotification.notificationRecords
  //         .filter((record): record is NotificationData => record !== null)
  //         .map(formatNotification);
  //     const validateId = newNotifications[0].description.userEventId;
  //     if (validateId === userId) {
  //       setNotifications((prev) => [...newNotifications, ...prev]);
  //     }
  //   }
  // }, [newNotificationData]);

  useEffect(() => {
    onToggleBlur(isOpen);
  }, [isOpen, onToggleBlur]);

  useEffect(() => {
    if (postId) {
      loadPost({ variables: { postId } });
      openPostDetail();
    }
  }, [postId, loadPost, openPostDetail]);

  const [updateNotification] = useMutation(UpdateNotificationStatusDocument);

  const [unreadNotifications, setUnreadNotifications] = useState<
    NotificationData[]
  >([]);

  useEffect(() => {
    const filteredNotifications = notifications.filter(
      (notification) => !notification.status
    );
    setUnreadNotifications(filteredNotifications);
  }, [notifications]);

  const markAllAsRead = useCallback(async () => {
    try {
      await Promise.all(
        notifications.map(async (notification) => {
          if (!notification.status) {
            await updateNotification({
              variables: {
                updatedNotificationStatusInput: {
                  userId: userId as string,
                  recordId: notification._id, // eslint-disable-line no-underscore-dangle
                },
              },
            });
          }
        })
      );
      await refetchNotificationsData();
    } catch (error) {
      return error;
    }
  }, [notifications, updateNotification, userId, refetchNotificationsData]);

  const handleMenuToggle = useCallback(() => {
    setIsOpen((prev) => {
      const newState = !prev;
      onToggleBlur(newState);
      return newState;
    });
  }, [onToggleBlur]);

  const markAsRead = useCallback(
    async (id: string) => {
      try {
        await updateNotification({
          variables: {
            updatedNotificationStatusInput: {
              userId: userId as string,
              recordId: id,
            },
          },
        });
        await refetchNotificationsData();
      } catch (error) {
        return error;
      }
    },
    [updateNotification, userId, refetchNotificationsData]
  );

  const handleNotificationClick = useCallback(
    (notification: NotificationData) => {
      setPostId(notification.description.eventId);
      markAsRead(notification._id); // eslint-disable-line no-underscore-dangle
    },
    [markAsRead]
  );

  const handleClosePostDetail = useCallback(() => {
    closePostDetail();
    setPostId(undefined);
  }, [closePostDetail]);

  if (userNotificationsLoading) {
    return (
      <Flex justify={"center"} align={"center"}>
        <BigSpinner />
      </Flex>
    );
  }
  return (
    <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
      {!user && !isMobile && (
        <Flex gap={2} maxH={"32px"}>
          <Button
            fontSize="14px"
            variant="link"
            border={"none"}
            px={4}
            py={2}
            onClick={() => navigate(PublicRoute.Login)}
          >
            Inicia sesión
          </Button>
          <Button
            fontSize="14px"
            variant="primary"
            px={4}
            py={2}
            onClick={() => navigate(PublicRoute.SignUp)}
          >
            Regístrate gratis
          </Button>
        </Flex>
      )}
      {user && (
        <Box position="relative" display="inline-block">
          <MenuButton
            data-cy={TestIds.HeaderMenuButton}
            as={IconButton}
            w="32px"
            h="32px"
            aria-label="Notification button"
            bg="transparent"
            _hover={{ backgroundColor: colors.neutral[50] }}
            _active={{ backgroundColor: "#E9F1FF" }}
            onClick={handleMenuToggle}
          >
            <Flex alignItems="center" justifyContent="center" w="100%" h="100%">
              {unreadNotifications.length > 0 ? (
                <Ico.NotificationBellActive fontSize="24px" />
              ) : (
                <Ico.NotificationBell
                  color={isOpen ? colors.primary[400] : colors.secondary[200]}
                  fontSize="20px"
                />
              )}
            </Flex>
          </MenuButton>
          {isOpen && (
            <Box
              position="absolute"
              bottom={-2.5}
              left="50%"
              transform="translateX(-50%)"
              w="100%"
              h="4px"
              bg={colors.primary[400]}
              borderRadius="6px"
              transition="all 0.3s ease"
            />
          )}
        </Box>
      )}
      <MenuList
        display="flex"
        justifyContent={"space-between"}
        flexDirection="column"
        py={{ base: 4, lg: 6 }}
        gap={{ base: 2, lg: 4 }}
        rounded={4}
        borderWidth="1px"
        mt="2px"
        w={{ base: "328px", lg: "430px" }}
        h={"100%"}
        minH={"331px"}
        zIndex={1500}
      >
        <Flex
          flexDir="column"
          justifyContent={"space-between"}
          h={"100%"}
          gap={{ base: 0, lg: 2 }}
        >
          <Flex
            flexDirection="column"
            gap={4}
            position="sticky"
            top="0"
            bg="white"
            zIndex="1"
          >
            <Text
              px={{ base: 4, lg: 6 }}
              fontSize={{ base: "16px", lg: "18px" }}
              fontWeight="600"
            >
              {t("Notifications")}
            </Text>
            <Tabs display="flex" flexDirection="column" gap={4}>
              <TabList
                mx={{ base: 4, lg: 6 }}
                w="fit-content"
                borderColor={"neutral.200"}
              >
                <Tab
                  pt={0}
                  fontSize="14px"
                  fontWeight="600"
                  color={"neutral.200"}
                  _selected={{
                    backgroundColor: "transparent",
                    color: "secondary.300",
                    borderColor: "secondary.300",
                    borderBottom: "2px",
                    mb: "-2px",
                  }}
                >
                  {t("Unread")}
                </Tab>
                <Tab
                  pt={0}
                  fontSize="14px"
                  fontWeight="600"
                  color={"neutral.200"}
                  _selected={{
                    backgroundColor: "transparent",
                    color: "secondary.300",
                    borderColor: "secondary.300",
                    borderBottom: "2px",
                    mb: "-2px",
                  }}
                >
                  {t("All notifications")}
                </Tab>
              </TabList>
              <TabPanels
                overflowY="scroll"
                maxHeight={{ base: "200px", lg: "400px" }}
                css={{
                  "&::-webkit-scrollbar": { width: "0px", height: "0px" },
                }}
              >
                <TabPanel py={0} px={{ base: 2, lg: 3 }}>
                  {unreadNotifications.length > 0 ? (
                    unreadNotifications.map((notification) => (
                      <NotificationItem
                        key={notification._id} // eslint-disable-line no-underscore-dangle
                        notification={notification}
                        handleNotificationClick={() =>
                          handleNotificationClick(notification)
                        }
                      />
                    ))
                  ) : (
                    <Flex
                      alignItems={"center"}
                      flexDir={"column"}
                      pt={10}
                      gap={2}
                    >
                      <Ico.BellNotifications fontSize={"40px"} />
                      <Text>{t("You have no unread notifications")}</Text>
                    </Flex>
                  )}
                </TabPanel>
                <TabPanel py={0} px={{ base: 2, lg: 3 }}>
                  {notifications.length > 0 ? (
                    notifications.map((notification) => (
                      <NotificationItem
                        key={notification._id} // eslint-disable-line no-underscore-dangle
                        notification={notification}
                        handleNotificationClick={() =>
                          handleNotificationClick(notification)
                        }
                      />
                    ))
                  ) : (
                    <Flex
                      alignItems={"center"}
                      flexDir={"column"}
                      pt={10}
                      gap={2}
                    >
                      <Ico.BellNotifications fontSize={"40px"} />
                      <Text>{t("You don't have notifications")}</Text>
                    </Flex>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
        <Flex flexDir={"column"} gap={6}>
          <MenuDivider
            my={0}
            mx={{ base: 4, lg: 6 }}
            border="1px"
            borderColor="neutral.200"
            display={unreadNotifications.length > 0 ? "block" : "none"}
          />
          <Button
            w={"fit-content"}
            variant="text"
            fontSize="14px"
            position="sticky"
            bottom="0"
            bg="shades.white"
            zIndex="1"
            px={{ base: 4, lg: 6 }}
            py={0}
            cursor="pointer"
            onClick={markAllAsRead}
            _hover={{ color: "gray.500" }}
            _active={{ color: "neutral.500" }}
            display={unreadNotifications.length > 0 ? "block" : "none"}
            title={t("Mark all as read")}
          />
        </Flex>
      </MenuList>
      {postDetailData?.getPostById && (
        <PostDetail
          isOpen={openDetail}
          onClose={handleClosePostDetail}
          selectedPost={postDetailData.getPostById as Post}
          refetchPosts={refetchNotificationsData}
        />
      )}
    </Menu>
  );
};

export { NotificationsMenu };
