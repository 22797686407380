import { PageContainer } from "@/components/PageContainer";
import OnlinePrograms from "../../OnlinePrograms";
import LiveSessions from "../LiveClasses/LiveSessions";
import GuideTraining from "../../GuideTraining";
import { Box, Divider, Flex, useBreakpointValue } from "@chakra-ui/react";
import SubCategoryFilter from "./subCategoryFilter/subCategoryFilter";
import BrandFilter from "./brandFilter/brandFilter";
import StartGuide from "./startGuide";
import { ContinueProgress } from "@/components/ContinueToProgress/continueProgress";
import { LearnParams } from "./providers/types";
import { useParams } from "react-router-dom";
import OnboardingBanner from "@/components/OnboardingBanner";
import { useContext, useEffect } from "react";
import { onboardingContext } from "./providers/onboardingProvider";
import ModalOnboarding from "@/components/ModalOnboarding";
import BannerBCMM from "@/components/BannerBC&MM/BannerBC&MM";
import useBannerBCMM from "@/components/BannerBC&MM/useBannerBCMM";
import { learnContext } from "./providers/learnProvider";
import { useUser } from "@/providers/useUser";
import MentorshipsCarrousel from "./mentorships/MentorshipsCarrousel";
import HeroBanner from "./HeroBanner";
//import MentorFilter from "./components/mentorFilter";
import { BackButton } from "@/components/PageContainer/BackButton";

const Learn = () => {
  const { subroute: selectedSubpage } = useParams<LearnParams>();
  const prov = useContext(learnContext);
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { isPro } = useUser();
  const { isComponentVisible, validateIsScheduled, quantityScheduled } =
    useContext(onboardingContext);

  const { shouldShowBanner, data: bannerData } = useBannerBCMM({
    productsBanner: prov.bootcamps?.myBootcamps ?? [],
  });
  useEffect(() => {
    if (!selectedSubpage) {
      prov.setSelectedBrand(undefined);
    }
  }, [prov, selectedSubpage]);
  const gapY = "16px";

  return (
    <PageContainer
      noGap
      px={isMobile ? 0 : "32px"}
      mt={isMobile ? "24px" : "40px"}
      pb={isMobile ? "80px" : "136px"}
    >
      {selectedSubpage && (
        <Box my={gapY} px={isMobile ? "16px" : "0"} w={"100%"} zIndex={0}>
          <BackButton to={-1} text="Back" />
        </Box>
      )}
      {!selectedSubpage && <HeroBanner />}

      {isPro &&
      (isComponentVisible || (validateIsScheduled && quantityScheduled > 0)) ? (
        <Flex justifyContent={"center"} mt={4} px={isMobile ? "16px" : "0"}>
          <OnboardingBanner />
        </Flex>
      ) : null}

      <Flex px={isMobile ? "16px" : "0"} flexDirection={"column"}>
        {shouldShowBanner ? (
          <BannerBCMM bannerData={bannerData} />
        ) : (
          <ContinueProgress />
        )}
      </Flex>

      <Flex
        px={isMobile ? "16px" : 0}
        gap={isMobile ? "16px" : "18px"}
        flexDir={isMobile ? "column" : "row"}
        justifyContent={"space-between"}
        w={"100%"}
        mt={4}
      >
        {isMobile && !selectedSubpage && <StartGuide />}

        {selectedSubpage ? <SubCategoryFilter /> : <BrandFilter />}
      </Flex>

      {/* <Flex
        mb={"24px"}
        justifyContent={"space-between"}
        alignItems={"flex-end"}
        px={isMobile ? "16px" : "0"}
      >
        { <MentorFilter />}
        {!isMobile && !selectedSubpage && <StartGuide />}
      </Flex> */}

      <Flex py={4} justifyContent={"flex-end"} alignItems={"center"}>
        {!isMobile && !selectedSubpage && <StartGuide />}
      </Flex>

      <Divider borderBottomWidth={"0.5px"} />

      <LiveSessions isColumn={false} />

      <Divider borderBottomWidth={"0.5px"} />

      <OnlinePrograms />

      <Divider borderBottomWidth={"0.5px"} />

      <GuideTraining />

      <Divider borderBottomWidth={"0.5px"} />

      <MentorshipsCarrousel />
    </PageContainer>
  );
};

export default Learn;
