import React from "react";
import { AppRoute, PublicRoute } from "@/AppRoute";
import { BackButton } from "@/components/PageContainer/BackButton";
import {
  Button,
  Divider,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Ico } from "@/assets/icons";
import { colors } from "@/components/Theme/colors";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "@/providers/useUser";
import { Video } from "../Media/Video";
import { useCustomNavigation } from "@/providers/Navigation/useCustomNavigation";
import { EntityName } from "@/schemaTypes";

interface ProgramBannerProps {
  thumbnailUrl: string;
  description?: string;
  quantProgramHours?: string;
  programType: string;
  videoUrl?: string;
  sessionId?: string;
  programName: string;
}

export const ProgramBanner: React.FC<ProgramBannerProps> = ({
  thumbnailUrl,
  description,
  quantProgramHours,
  programType,
  videoUrl,
  sessionId,
  programName,
}) => {
  const { courseId, cohortId, programId, phaseId } = useParams<
    "courseId" | "programId" | "cohortId" | "phaseId"
  >();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isUserSubscribed } = useUser();
  const { configUpsellRedirect } = useCustomNavigation();
  return (
    <Flex position="relative" overflow={"overlay"} justifyContent={"center"}>
      <Image
        src={thumbnailUrl}
        alt="Background"
        position="absolute"
        w="100%"
        h="100%"
        objectFit="fill"
        zIndex={1}
        filter="blur(100px)"
        opacity={"40%"}
      />
      <Flex
        flexDir={"column"}
        maxW={"994px"}
        gap={4}
        py={{ base: 4, md: 8 }}
        px={{ base: 4, xl: 0 }}
      >
        <Flex>
          <BackButton
            showLabel={!isMobile}
            to={AppRoute.Learn}
            onGoBack={() => {}}
            text={t("Back")}
          />
        </Flex>
        <Flex
          flexDir={{ base: "column", md: "row" }}
          gap={{ base: 6, md: "52px" }}
          zIndex={2}
        >
          {!videoUrl && (
            <Image
              w={{ base: "100%", md: "364px", xl: "564px" }}
              h={{ xl: "318px" }}
              borderRadius={"6px"}
              src={thumbnailUrl}
              style={{ objectFit: "cover" }}
              alignSelf={"baseline"}
            />
          )}

          {videoUrl && (
            <Video
              w={{ base: "100%", md: "364px", xl: "564px" }}
              h={{ xl: "318px" }}
              borderRadius={"6px"}
              roundedTop={"8px"}
              video={{ url: videoUrl, playing: false }}
            />
          )}
          <Flex
            flexDirection={"column"}
            gap={{ base: 8, xl: 12 }}
            pt={{ base: 0, md: 1 }}
            pb={{ base: 4, md: 0 }}
            w={{ base: "100%", md: "328px" }}
          >
            <Flex flexDirection={"column"} gap={4}>
              <Text fontSize={"14px"}>{description}</Text>
              <Divider borderBottomWidth={"1px"} borderColor={"white"} />
              <Flex pb={2} flexDirection={"column"} gap={"11px"}>
                <Flex gap={2} alignItems={"center"}>
                  <Ico.Clock2 fontSize={"20px"} color={colors.primary[200]} />
                  <Text fontSize={"14px"} fontWeight={"bold"}>
                    {quantProgramHours} de estudio
                  </Text>
                </Flex>
                <Flex gap={2} alignItems={"center"}>
                  <Ico.LaptopPlay
                    fontSize={"20px"}
                    color={colors.primary[200]}
                  />
                  <Text fontSize={"14px"} fontWeight={"bold"}>
                    {t("Online, in video, and at your own pace")}
                  </Text>
                </Flex>
                <Flex gap={2} alignItems={"center"}>
                  <Ico.Certificate
                    fontSize={"20px"}
                    color={colors.primary[200]}
                  />
                  <Text fontSize={"14px"} fontWeight={"bold"}>
                    {t("You will receive your digital certificate")}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Button
              h={10}
              rightIcon={<Ico.ArrowRight fontSize={"20px"} />}
              variant={"primary"}
              px={4}
              fontSize={"14px"}
              onClick={() => {
                let redirRoute: string = "";
                if (programType !== t("ADVANCED_COURSE")) {
                  redirRoute = `${AppRoute.Programs}/${programId}${AppRoute.Courses}/${phaseId}${AppRoute.Cohorts}/${cohortId}${AppRoute.Dashboard}`;
                } else if (programType === t("ADVANCED_COURSE")) {
                  redirRoute = `${AppRoute.AdvancedCourse}/${courseId}${AppRoute.Cohorts}/${cohortId}${AppRoute.Sessions}/${sessionId}${AppRoute.InteractiveContent}`;
                }
                if (isUserSubscribed) {
                  navigate(redirRoute, { replace: true });
                } else {
                  configUpsellRedirect(
                    programType as EntityName,
                    programName,
                    redirRoute,
                    true
                  );
                  navigate(PublicRoute.SignUp);
                }
              }}
            >
              {isUserSubscribed ? t("Start") : t("Start free")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
