import AcademyCarousel from "@/components/AcademyCarousel";
import { BootcampCard } from "@/components/Bootcamps/BootcampCard";
import { useEnrollmentUtils } from "@/components/Bootcamps/Enrollment/useEnrollmentUtils";
import { BootcampCardModal } from "@/components/Bootcamps/Modal/modalCard";
import { DataProps } from "@/components/Bootcamps/Modal/utils";
import { BootcampBannerEntry, EntityName } from "@/schemaTypes";
import { useSubscription } from "@apollo/client";
import {
  Box,
  Flex,
  Heading,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import {
  EnrollmentDocument,
  EnrollmentSubscription,
} from "./graphql/enrollUserInMultipleCohortsSubscrition.generated";
import { learnContext } from "../academy/Learn/providers/learnProvider";
import { BigSpinner } from "@/components/BigSpinner";
import EmpityStateComponent from "@/components/EmpityStates/EmpityStateComponent";
import { Ico } from "@/assets/icons";

enum BannerNames {
  CTA,
  MYBOOTCAMPS,
  COMPLETED,
}

interface BootcampArray {
  completed: BootcampBannerEntry[];
  cta: BootcampBannerEntry[];
  myBootcamps: BootcampBannerEntry[];
}

// THIS INTERFACE IS DEPRECATED. PLEASE, DO NOT USE IT.
// refer to learnContext instead
interface GuideTrainingProps {
  selectSubCategory?: string | null;
  bootcamps?: {
    completed: BootcampBannerEntry[];
    cta: BootcampBannerEntry[];
    myBootcamps: BootcampBannerEntry[];
  };
  mainFilter?: string;
  selectedSubcategory?: string | null;
  subcategories?: (subcategories: string[]) => void;
  refetchBootcamps?: () => void;
}

const GuideTraining: React.FC<GuideTrainingProps> = () => {
  const context = useContext(learnContext);

  if (!context) {
    throw new Error("You probably forgot to put <LearnProvider>.");
  }

  const { bootcamps, refetchBootcamps, bootcampsLoading, selectedSubCategory } =
    context;

  const { isOpen: openCreate, onOpen, onClose: handleReset } = useDisclosure();
  const [modalProps, setModalProps] = useState<DataProps>();
  const { blockBcUserEnroll, handleBlockBcUserEnroll } = useEnrollmentUtils();

  // useSubscription<EnrollmentSubscription>(EnrollmentDocument, {
  //   onSubscriptionData: () => {
  //     refetchBootcamps();
  //   },
  // });

  const [AllBootcamps, setAllBootcamps] = useState<BootcampArray>({
    completed: [],
    cta: [],
    myBootcamps: [],
  });

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const getParentCategories = (data: BootcampBannerEntry[]) => {
    const allCategories = data?.map(
      (bootcamp: BootcampBannerEntry) => bootcamp?.parent?.bootcampType
    );
    const desiredOrder = ["Bootcamps", "Mastermind"];

    const orderedCategories = allCategories.sort((a, b) => {
      return (
        desiredOrder.indexOf(a as string) - desiredOrder.indexOf(b as string)
      );
    });
    const sanitizedCategories = orderedCategories.map((category) =>
      category?.toString() === EntityName.MastermindBootcamp
        ? "Mastermind"
        : "Bootcamps"
    );
    const uniqueCategories = new Set(sanitizedCategories);

    return ["Todos", ...uniqueCategories] as string[];
  };

  const bootcampsFilter = (
    filter: string,
    bootcampList: BootcampBannerEntry[]
  ): BootcampBannerEntry[] => {
    if (filter === "Mastermind") {
      return bootcampList.filter(
        (bootcamp: BootcampBannerEntry) =>
          bootcamp?.parent?.bootcampType?.toLocaleLowerCase() ===
          EntityName.MastermindBootcamp.toLocaleLowerCase()
      );
    }
    return bootcampList.filter(
      (bootcamp: BootcampBannerEntry) =>
        bootcamp?.parent?.bootcampType?.toLocaleLowerCase() !==
        EntityName.MastermindBootcamp.toLocaleLowerCase()
    );
  };

  const updateBootcamps = (
    filter: string,
    key: keyof typeof bootcamps,
    bootcampData: typeof bootcamps
  ) => {
    if (filter === "Todos") {
      setAllBootcamps((prev) => ({ ...prev, [key]: bootcampData[key] }));
    } else {
      const filteredBootcamps = bootcampsFilter(filter, bootcampData[key]);
      setAllBootcamps((prev) => ({ ...prev, [key]: filteredBootcamps }));
    }
  };

  const handleFilterChange = (filter: string) => {
    switch (selectedTab) {
      case 0:
        updateBootcamps(filter, "cta", bootcamps);
        break;
      case 1:
        updateBootcamps(filter, "myBootcamps", bootcamps);
        break;
      case 2:
        updateBootcamps(filter, "completed", bootcamps);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setAllBootcamps(bootcamps);
  }, [bootcamps]);

  useEffect(() => {
    const filterBootcamps = () => {
      let filteredBootcamps = { ...bootcamps };
      if (selectedSubCategory !== "Todos" && selectedSubCategory !== null) {
        filteredBootcamps = {
          completed: filteredBootcamps.completed.filter(
            (bootcamp: BootcampBannerEntry) =>
              bootcamp?.parent?.subCategory?.[0]?.name === selectedSubCategory
          ),
          cta: filteredBootcamps.cta.filter(
            (bootcamp: BootcampBannerEntry) =>
              bootcamp?.parent?.subCategory?.[0]?.name === selectedSubCategory
          ),
          myBootcamps: filteredBootcamps.myBootcamps.filter(
            (bootcamp: BootcampBannerEntry) =>
              bootcamp?.parent?.subCategory?.[0]?.name === selectedSubCategory
          ),
        };
      }
      setAllBootcamps(filteredBootcamps);
    };

    filterBootcamps();
  }, [selectedSubCategory]);

  const handleBlockEnroll = (bootcamp: BootcampBannerEntry) => {
    handleBlockBcUserEnroll(bootcamp, setModalProps, onOpen);
  };

  const handleSubmitModal = (bootcamp: BootcampBannerEntry, userId: string) => {
    blockBcUserEnroll(bootcamp, userId, setModalProps, onOpen);
  };
  return (
    <>
      <Flex w={"100%"} my={"24px"} flexDir={"column"}>
        <Heading as={"h5"} ml={{ base: "16px", lg: 0 }} mb={"16px"}>
          <Trans>Entrenamientos con Guía</Trans>
        </Heading>
        {bootcampsLoading && <BigSpinner />}

        {!bootcampsLoading && (
          <Tabs isFitted index={selectedTab} onChange={setSelectedTab}>
            <TabList
              color={"neutral.300"}
              ml={{ base: "16px", lg: 0 }}
              maxW={"max-content"}
            >
              {["Following", "List", "Done"].map((tab, index) => (
                <Tab
                  key={tab}
                  pt={0}
                  gap={"0.25rem"}
                  _selected={{ color: "secondary.300" }}
                  _active={{ color: "secondary.300" }}
                >
                  <Text variant={"caption"}>
                    <Trans>{tab}</Trans>
                  </Text>
                  {index === 1 && (
                    <Flex
                      w={"20px"}
                      h={"20px"}
                      m={"2px"}
                      bg={"secondary.300"}
                      borderRadius={"50%"}
                      justify={"center"}
                      align={"center"}
                    >
                      <Text variant={"feedback"} color={"#fff"}>
                        {bootcamps.myBootcamps.length}
                      </Text>
                    </Flex>
                  )}
                </Tab>
              ))}
            </TabList>

            <TabIndicator
              mt="-2px"
              height="2px"
              bg="secondary.300"
              borderRadius="8px"
            />

            <TabPanels>
              <TabPanel p={0} mt={"16px"}>
                <AcademyCarousel
                  isColumn={false}
                  filterOptions={
                    AllBootcamps.cta.length > 0
                      ? getParentCategories(bootcamps.cta)
                      : []
                  }
                  handleFilterChange={handleFilterChange}
                >
                  {AllBootcamps.cta.length > 0
                    ? AllBootcamps.cta &&
                      AllBootcamps.cta.map(
                        (bootcamp: BootcampBannerEntry, index) => (
                          <BootcampCard
                            key={bootcamp.release?.id || `bootcamp-${index}`}
                            bootcampEntry={bootcamp}
                            targetBanner={BannerNames.CTA}
                            isJoinnable={false}
                            isCta={true}
                          />
                        )
                      )
                    : [
                        <Text
                          key={1}
                          mt={"16px"}
                          ml={"0"}
                          variant={"placeholder"}
                        >
                          <Trans>
                            At this time there are no upcoming Bootcamps.
                          </Trans>
                        </Text>,
                      ]}
                </AcademyCarousel>
              </TabPanel>
              <TabPanel p={0} mt={"16px"}>
                <AcademyCarousel
                  isColumn={false}
                  filterOptions={
                    AllBootcamps.myBootcamps.length > 0
                      ? getParentCategories(bootcamps.myBootcamps)
                      : []
                  }
                  handleFilterChange={handleFilterChange}
                >
                  {AllBootcamps.myBootcamps.length > 0
                    ? AllBootcamps.myBootcamps.map(
                        (bootcamp: BootcampBannerEntry, index) => (
                          <BootcampCard
                            key={
                              bootcamp.release?.title &&
                              bootcamp.release?.title + index
                            }
                            bootcampEntry={bootcamp}
                            targetBanner={BannerNames.MYBOOTCAMPS}
                            isJoinnable={false}
                            onEditClick={() => {}}
                            blockEnrroll={handleBlockEnroll}
                          />
                        )
                      )
                    : [
                        <Box
                          key="empty-state"
                          mt={{ base: "36px", lg: "40px" }}
                          mb={{ base: "40px", lg: "96px" }}
                          w={"100vw"}
                        >
                          <EmpityStateComponent
                            IconComponent={Ico.EmpityState1}
                            dataFontSize={{ base: "82px", lg: "168px" }}
                            textUp={
                              "You don't have any Bootcamps in your schedule yet!"
                            }
                            textDown={"Are you signing up for one?"}
                          />
                        </Box>,
                      ]}
                </AcademyCarousel>
              </TabPanel>
              <TabPanel p={0} mt={"16px"}>
                <AcademyCarousel
                  isColumn={false}
                  filterOptions={
                    AllBootcamps.completed.length > 0
                      ? getParentCategories(bootcamps.completed)
                      : []
                  }
                  handleFilterChange={handleFilterChange}
                >
                  {AllBootcamps.completed.length > 0
                    ? AllBootcamps.completed &&
                      AllBootcamps.completed.map(
                        (bootcamp: BootcampBannerEntry, index) => (
                          <BootcampCard
                            key={
                              bootcamp.release?.title &&
                              bootcamp.release?.title + index
                            }
                            bootcampEntry={bootcamp}
                            targetBanner={BannerNames.COMPLETED}
                            isJoinnable={true}
                            onEditClick={() => {}}
                          />
                        )
                      )
                    : [
                        <Box
                          key="empty-state"
                          mt={{ base: "36px", lg: "72px" }}
                          mb={{ base: "40px", lg: "96px" }}
                          w={"100vw"}
                        >
                          <EmpityStateComponent
                            IconComponent={Ico.EmpityState2}
                            dataFontSize={{ base: "40px", lg: "64px" }}
                            textUp={"You haven't completed any Bootcamps yet."}
                            textDown={
                              "Do you feel like starting the first one?"
                            }
                          />
                        </Box>,
                      ]}
                </AcademyCarousel>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </Flex>

      <BootcampCardModal
        data={modalProps}
        isOpen={openCreate}
        onClose={handleReset}
        onSubmit={handleSubmitModal}
      />
    </>
  );
};

export default GuideTraining;
